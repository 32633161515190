<template>
    <div>
        <div class="img-plateforme" id="img-plateforme">
            <div>
                <v-row >
                    <v-col cols="12" sm="9" class="text-home">
                        <h1 class="big-title">Une Plateforme agile pour l'intégration de vos capteurs</h1>
                    </v-col>
                </v-row>
            </div>
        </div>
        <v-row>
            <v-col cols="12" sm="1" md="1" lg="1"></v-col>
            <v-col cols="12" sm="10" md="4" lg="4" class="mt50 mb50  pd5" >
                <div id="le-biotagsensor" class="trais"></div>
                <h1 class="green-title">L’implant Plateforme</h1>
                <p class="text-classi"> <br>L'implant BioTag, miniature et robuste, intègre une électronique évolutive qui met en relation une multitude de capteurs. <br><br>

La plateforme BioTagSensor reprend les innovations mécaniques, électroniques et d’intégration de l’implant BioTag. <br><br>

La plateforme est donc un excellent support qualifié pour la R&D, le test ou la qualification de nouveaux capteurs ou de solutions implantables. <br><br>

Vous êtes porteur d’une solution ?<br>
Vous avez un concept innovant à mettre en œuvre ?<br>
Vous souhaitez intégrer votre solution dans un implant ?<br><br>

Par son expérience, l’équipe de BioTagSensor possède déjà un fort savoir-faire en électronique, en capteurs, en intégration et production d’implant. <br> <br>

Collaborons donc ensemble à la réussite de votre projet implantable ! <br>

                </p>
                <div class="btn-loc">
                     <router-link to="/contact" id="btn" style="left: 65%; top: 5%;">Contactez-nous</router-link>
                </div>
            </v-col>
           <v-col sm="1" md="1" lg="2"></v-col>
            <v-col cols="12" sm="10" md="4" lg="4">
                <div class="img-implant-plateforme">
                    <img src="../assets/schema/schéma_plateforme.png" alt="schéma ipmplant plateforme">
                </div>
            </v-col>
        </v-row>
        <foot/>
    </div>
</template>

<script>
import foot from '@/components/Footer.vue'
export default {
    components:{
      foot
    },
}
</script>

<style>

.img-plateforme{
    background-size: cover;
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    text-align: left;
    position: relative;
}

#img-plateforme{
    background-image: url(../images/travail_collaboratif_high.jpg);
}

.img-plateforme::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* Ajout du filtre opaque noir */
    background-color: rgba(0, 55, 109, 0.33);
}

.text-home {
    position: relative;
    top: 150px;
  }

.text-home h1{
    margin-left: 5%;
    width: 90%;
    margin-bottom: 5px;
}

.img-implant-plateforme{
    margin-top: 150px
}

.img-implant-plateforme img{
    width: 100%;
    transition: transform 0.5s ease-in-out;
}


.img-implant img.animated {
  transform-origin: center;
  transition: transform 0.5s ease-in-out;
}

@media (max-width: 1264px) {


.img-plateforme{
    margin-top: 130px;
    height: 80vh;
}

.img-implant-plateforme{
    margin-top: 20px;
}
}

</style>


